import { createRoot } from 'react-dom/client';
import { ListGroup } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

document.title = 'Debug applications';

createRoot(document.getElementById('app')!).render(
    <ListGroup>
        <ListGroup.Item action href="./app/">App</ListGroup.Item>
        <ListGroup.Item action href="./app/logger.html">App with nav logger</ListGroup.Item>
        <ListGroup.Item action href="./ar/">AR</ListGroup.Item>
        <ListGroup.Item action href="./camera/">Camera</ListGroup.Item>
        <ListGroup.Item action href="./geo/">Geo</ListGroup.Item>
        <ListGroup.Item action href="./map/">Map</ListGroup.Item>
        <ListGroup.Item action href="./providers/">Providers</ListGroup.Item>
        <ListGroup.Item action href="./routers/">Routers</ListGroup.Item>
    </ListGroup>
);
